
import {computed, defineComponent, ref, reactive, watch} from "vue";
import {
  alertController,
  IonButton, IonCard, IonCardContent, IonCardHeader,
  IonCol,
  IonContent,
  IonGrid, IonHeader, IonInput,
  IonItem, IonItemDivider, IonLabel, IonList,
  IonPage,
  IonRow, IonSearchbar, IonTitle, IonToolbar, isPlatform,
  onIonViewWillEnter
} from "@ionic/vue";
import {
  CreateExitDelivery, CreateExitDeliveryItem,
} from "@/domain/command/delivery/delivery.command";
import {useStore} from "vuex";
import router from "@/app/router";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import _ from "lodash";
import {Order} from "@/domain/model/order/order.model";
import CreateExitDeliveryOrdersCardComponent
  from "@/app/view/delivery/create/CreateExitDeliveryOrdersCardComponent.vue";
import CreateExitDeliveryTableItemComponent from "@/app/view/delivery/create/CreateExitDeliveryTableItemComponent.vue";
import {useOrderApi} from "@/domain/service/api/order.api";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {Material} from "@/domain/model/material/material.model";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";

export default defineComponent({
  name: "CreateExitDeliveryPage",
  components: {
    CreateExitDeliveryOrdersCardComponent,
    CreateExitDeliveryTableItemComponent,
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonGrid,
    IonRow,
    IonButton,
    IonCol,
    IonSearchbar,
    IonItemDivider,
    IonList,
    IonHeader,
    IonLabel,
    IonInput
  },

  setup() {
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const {exitDeliveryBeforeCheck} = useViewStateApi();
    const orders = computed<Order[]>(() => store.getters.allActiveOrders);
    const command = ref<CreateExitDelivery>(store.getters.getCheckCreateExitDeliveryState);
    const exitDeliveryIndex = ref<string>(store.getters.getNextExitDeliveryIndex);


    const searchWord = ref('');
    const maxResults = ref(7);
    const searchOrder = (item: CreateExitDeliveryItem, word: string): boolean => item.ref.toLowerCase().includes(word.toLowerCase()) || item.createProducedOrder.ref.toLowerCase().includes(word.toLowerCase());
    const filterOrder = (items: CreateExitDeliveryItem[], word: string): CreateExitDeliveryItem[] =>
        _.filter(items, (itemNode: CreateExitDeliveryItem) => searchOrder(itemNode, word));
    const exitDeliveryItemList = computed(() => filterOrder(command.value.items, searchWord.value).slice(0, maxResults.value));

    const onIndexIsNotFree = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Número de albarán de salida inválido',
            message: 'El número de albarán de salida  que ha introducido ya existe o está vacío, introduzca uno distinto',
            buttons: [
              {
                text: 'Ok',
                role: 'success',
                cssClass: 'danger',
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }

    const filterValidItems = (items: Array<any>) => _.reject(items, (item) => {
      return item.reference === ''
    });
    const isAtLeastOneItem = computed<boolean>(() => {
      return filterValidItems(command.value.items).length > 0
    });
    const onSendToCheck = async () => {
      const newCommand: CreateExitDelivery = command.value;
      newCommand.items = filterValidItems(newCommand.items);
      if(!exitDeliveryIndex.value){
        await onIndexIsNotFree();
        return;
      }
      newCommand.indexPosition = exitDeliveryIndex.value;

      await useDeliveryApi().isExitDeliveryIndexUsed(_.cloneDeep(exitDeliveryIndex.value));
      if(!store.getters.getIsThisIndexFree){
        await onIndexIsNotFree();
        return;
      }

      exitDeliveryBeforeCheck(newCommand);

      await router.push({name: `checkExitDelivery`, params: {id: newCommand.id}});
    };

    useMaterialApi().fetchAll();
    useOrderApi().fetchAll();

    onIonViewWillEnter(() => {
      useMaterialApi().fetchAll();
      useOrderApi().fetchAll();
      command.value = store.getters.getCheckCreateExitDeliveryState;
      exitDeliveryIndex.value = store.getters.getNextExitDeliveryIndex;
    });

    return {
      isTablet,
      onSendToCheck,
      command,
      isAtLeastOneItem,
      orders,
      exitDeliveryItemList,
      searchWord,
      exitDeliveryIndex,
    };
  }
})
